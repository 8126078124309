import React, { Component } from "react";

import { Avatar, Tooltip } from "@material-ui/core";
import styled from "styled-components";

import { ds } from "js/core/models/dataService";
import { SlideStatus } from "common/constants";
import { SlideStatusProps } from "common/constants";
import { StaticImage } from "js/Components/StaticImage";
import { Icon } from "js/Components/Icon";

import PresentationEditorController from "../PresentationEditorController";

const StyledCommentContainer = styled.div`
    :hover {
        background-color: none;
        cursor: pointer;
    }

    .MuiAvatar-root {
        width: 30px; 
        height: 30px;
    }    
`;

const StyledStatusTag = styled.div`
    width: 16px;
    height: 16px;
    position: absolute;
    left: ${({ assignedUser }) => assignedUser ? "19px" : "15px"};
    top: ${({ assignedUser }) => assignedUser ? "19px" : "15px"};
`;

export default class AssignSlide extends Component {
    state = {
        assignedUser: null,
    }

    componentDidMount() {
        this.slideStatusSubscription = ds.getObservables().slideStatus$
            .subscribe(status => this.setState({ slideStatus: status || SlideStatus.NONE }));

        this.assignedToSlideUserSubscription = ds.getObservables().assignedToSlideUser$
            .subscribe(assignedUser => this.setState({ assignedUser }));
    }

    componentWillUnmount() {
        this.slideStatusSubscription?.unsubscribe();
        this.assignedToSlideUserSubscription?.unsubscribe();
    }

    handleToggleAssignSlide = () => {
        PresentationEditorController.toggleAssignSlide();
    }

    handleUnassignUser = () => {
        const { currentSlide } = this.props;

        currentSlide.update({ assignedUser: null, assignedPendingUser: null });
    }

    render() {
        const { slideStatus, assignedUser } = this.state;

        const hasSlideStatus = slideStatus !== SlideStatus.NONE && SlideStatusProps[slideStatus];

        return (
            <Tooltip title="Assign slide" arrow>
                <StyledCommentContainer data-test-id="assign-to-user">
                    {assignedUser
                        ? <Avatar src={assignedUser.photoURL} alt={(assignedUser.displayName || assignedUser.email)} />
                        : <Icon><StaticImage src="/images/slide-status/no-assigned-user.svg" /></Icon>}
                    {hasSlideStatus && (<StyledStatusTag assignedUser={assignedUser}>
                        <Icon><StaticImage src={`/images/slide-status/small/${SlideStatusProps[slideStatus].icon}.svg`} /></Icon>
                    </StyledStatusTag>)}
                </StyledCommentContainer>
            </Tooltip>
        );
    }
}

