import React, { Component } from "react";
import { _, tinycolor } from "js/vendor";
import { trackActivity } from "js/core/utilities/utilities";
import ThemeSection from "js/editor/ThemeEditor/components/ThemeSection";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { BlueSwitch } from "js/react/components/Switch";
import { Slider } from "@material-ui/core";
import UploadLogo from "js/editor/ThemeEditor/components/Common/UploadLogo";
import { SimpleLabel } from "js/react/components/SimpleLabel";
import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import { ColorPicker } from "js/react/components/ColorPicker";
import Logos from "js/core/models/logos";
import { getStaticUrl } from "js/config";
import AppController from "js/core/AppController";
import Loadable from "js/react/components/Loadable";

const DEFAULT_LOGO = "/images/beautifulai-logos/beautifulai-logo-reverse.svg";

const defaultBranding = {
    backgroundColor: "#000",
    logoScale: 1,
    logoId: null,
    useLoadingAnimation: true
};

const AssetContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;

    & > div {
        margin-right: 20px;
    }
`;

const AssetScalePicker = styled.div`
    max-width: 245px;
    text-transform: uppercase;
`;

const BackgroundColorPicker = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    
    // removes padding from the Color Picker component
    > div {
        padding: 0;
    }
    
    button {
        border: 1px solid ${themeColors.gray};
        width: 30px;
        height: 30px;
    }
`;

const StyledPreview = styled.div`
    padding: 0 30px 30px 30px;
    
    .preview {
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 226px;
        max-width: 100%;
        aspect-ratio: 16 / 9;
        border: 1px solid ${themeColors.gray};
    }

    .content {
        display: grid;
        grid-auto-rows: auto;
        gap: 40px;

        > * {
            justify-content: center;
            display: flex;
        }
    }
    
    .logo img {
        max-width: 150px;
        max-height: 150px;
        width: auto;
        height: auto;
    }

    img.default {
        transform: scale(0.6);
    }

    img.loading-bar {
        transform: scale(0.25);
    }
`;

export class ThemeLoader extends Component {
    constructor(props) {
        super(props);

        let playerBranding = this.props.theme.playerBranding || {};

        this.state = {
            backgroundColor: playerBranding.backgroundColor ?? defaultBranding.backgroundColor,
            logoScale: playerBranding.logoScale ?? defaultBranding.logoScale,
            logoId: playerBranding.logoId,
            useLoadingAnimation: playerBranding.useLoadingAnimation ?? defaultBranding.useLoadingAnimation
        };

        this.trackingProps = {
            themeOrPresentationId: this.props.themeOrPresentationId
        };
    }

    updateValue = (name, value) => {
        let playerBranding = this.state;
        playerBranding = { ...playerBranding, [name]: value };
        this.setState(playerBranding);
        const includedKeys = ["backgroundColor", "logoScale", "useLoadingAnimation"];
        if (playerBranding.logoId) {
            includedKeys.push("logoId");
        }
        this.props.update({ playerBranding: _.pick(playerBranding, includedKeys) });
    }

    // determines a good background color for a logo
    detectBackgroundColor(rgb) {
        let color = tinycolor(rgb);

        // adjust colors
        if (color.isLight()) {
            color = color.desaturate(50).darken(30);
        } else {
            color = color.desaturate(50).lighten(40);
        }

        return color.toHexString();
    }

    handleSetLogo = async update => {
        let logoSrc;
        if ("logoId" in update) {
            const { logoId } = update;
            this.updateValue("logoId", logoId);

            if (!logoId) {
                this.setState({ logoSrc: null });
                this.updateValue("logoId", null);
                trackActivity("ThemeActions", "RemovedAsset", null, null, this.trackingProps);
            } else {
                // set a new logo
                logoSrc = await Logos.getSignedUrlAndLoad(logoId);
                this.setState({ logoSrc: logoSrc });
                trackActivity("ThemeActions", "UploadedAsset", null, null, this.trackingProps);
            }
        } else if ("averageImageColor" in update) {
            const { averageImageColor } = update;
            const backgroundColor = this.detectBackgroundColor(averageImageColor);
            this.updateValue("backgroundColor", backgroundColor);
        }
    }

    render() {
        const {
            backgroundColor,
            logoScale,
            logoId,
            useLoadingAnimation,
        } = this.state;

        return (
            <ThemeSection title="Theme Loader">
                <Gap20/>
                <SimpleLabel margin={0}>
                    Asset
                </SimpleLabel>

                <AssetContainer>
                    <UploadLogo
                        filePickerOnClick={!logoId}
                        attribute="logoId"
                        src={logoId}
                        update={this.handleSetLogo}
                        allowedFileTypes={["svg+xml", "png", "jpeg", "jpg", "gif"]}
                        disableImageProcessing={file => /\.(svg|gif)$/i.test(file.name)}
                        extractAverageImageColor
                    >
                    </UploadLogo>
                </AssetContainer>
                <Gap20/>

                <BlueSwitch
                    checked={useLoadingAnimation}
                    onChange={()=> {
                        this.updateValue("useLoadingAnimation", !useLoadingAnimation);
                        trackActivity("ThemeActions", "EditedLoaderBar", null, null, this.trackingProps);
                    }}
                    label="Loading Bar"
                />
                <Gap20/>
                <AssetScalePicker>
                    <SimpleLabel tooltip="Adjusts the scale of the asset.">
                        Asset Scale
                    </SimpleLabel>
                    <Slider
                        value={logoScale}
                        onChange={(event, scale)=>{
                            if (!this.state.scaleOnOpen) {
                                this.setState({ scaleOnOpen: this.state.logoScale });
                            }
                            this.updateValue("logoScale", scale);
                        }}
                        onChangeCommitted={(event, scale)=>{
                            this.updateValue("logoScale", scale);
                            if (this.state.scaleOnOpen != scale) {
                                trackActivity("ThemeActions", "EditedScaleAsset", null, null, this.trackingProps);
                                this.setState({ scaleOnOpen: null });
                            }
                        }}
                        min={0.8}
                        max={1.5}
                        step={0.05}
                    />
                </AssetScalePicker>
                <Gap20/>
                <BackgroundColorPicker>
                    <ColorPicker
                        disableAlpha
                        disablePalette
                        value={backgroundColor}
                        showColorPicker
                        onOpenPicker={()=> this.setState({ colorOnOpen: this.state.backgroundColor })}
                        onClosePicker={()=> {
                            if (this.state.colorOnOpen != this.state.backgroundColor) {
                                trackActivity("ThemeActions", "ChangedBackgroundColor", null, null, this.trackingProps);
                                this.setState({ colorOnOpen: null });
                            }
                        }
                        }
                        onChange={value=> this.updateValue("backgroundColor", value)}
                    />
                    <SimpleLabel>Background Color</SimpleLabel>
                </BackgroundColorPicker>
            </ThemeSection>
        );
    }
}

export class ThemeLoaderPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoSrc: null,
            isLoading: true
        };
    }

    componentDidMount() {
        const logoId = this.props.theme.playerBranding?.logoId;
        this.loadLogoSrc(logoId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.theme.playerBranding?.logoId != this.props.theme.playerBranding?.logoId) {
            this.loadLogoSrc(this.props.theme.playerBranding?.logoId);
        }
    }

    loadLogoSrc = async logoId => {
        const logoSrc = logoId
            ? await Logos.getSignedUrlAndLoad(logoId)
            : getStaticUrl(DEFAULT_LOGO);

        this.setState({ logoSrc, isLoading: false });
    }

    render() {
        let { theme } = this.props;
        let playerBranding = {
            backgroundColor: theme.playerBranding?.backgroundColor ?? defaultBranding.backgroundColor,
            logoId: theme.playerBranding?.logoId ?? defaultBranding.logoId,
            logoScale: theme.playerBranding?.logoScale ?? defaultBranding.logoScale,
            useLoadingAnimation: theme.playerBranding?.useLoadingAnimation ?? defaultBranding.useLoadingAnimation,
        };

        const {
            backgroundColor,
            logoScale,
            useLoadingAnimation
        } = playerBranding;
        const {
            isLoading,
            logoSrc
        } = this.state;
        const logoAltText = AppController.currentTeam?.attributes?.name ?? "Beautiful.ai";
        const logoStyle = { transform: `scale(${logoScale ?? 1})` };
        const previewStyle = { backgroundColor: (backgroundColor ?? "#000") };
        return (
            <StyledPreview>
                <Gap20/>
                <SimpleLabel margin={0}>
                    Preview
                </SimpleLabel>
                <Gap10/>
                <Loadable isLoading={isLoading}>
                    <div className="preview" style={previewStyle}>
                        <div className="content">
                            <div className="logo" style={logoStyle}>
                                <img src={logoSrc} alt={logoAltText}/>
                            </div>
                            {useLoadingAnimation && (
                                <img className="loading-bar" src={getStaticUrl("/images/splash-bar.gif")} alt="loading animation"/>
                            )}
                        </div>
                    </div>
                </Loadable>
            </StyledPreview>
        );
    }
}
