import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import styled from "styled-components";

import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { Presentation } from "js/core/models/presentation";
import { Slide } from "js/core/models/slide";
import { Theme } from "js/core/models/theme";
import PresentationEditor from "js/editor/PresentationEditor/PresentationEditor";
import PresentationEditorController, { PanelType } from "js/editor/PresentationEditor/PresentationEditorController";
import { ChooseThemeDialog } from "js/editor/ThemeEditor/ChooseThemeDialog";
import { ExportToPPT } from "js/exporter/exportToPPT";
import { app } from "js/namespaces";
import { ShowDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { UploadFontsDialog } from "js/react/components/Dialogs/UploadFontsDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { dialogTheme } from "js/react/materialThemeOverrides";
import AddSlideContainer from "js/react/views/AddSlide/AddSlideContainer";
import { Button } from "js/Components/Button";

import { FeatureType } from "common/features";
import PPTAddinEditorTopBar from "./PPTAddinEditorTopBar";
import SaveSlideDialog from "./SaveSlideDialog";
import SmartSlidesDialog from "./SmartSlidesDialog";

const logger = getLogger(LogGroup.PPT_ADDIN);

const MainViewEditorContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #4b4e55;
`;

const MainViewEditorFrame = styled.div`
  position: absolute;
  width: 100%;
  top: 50px;
  height: calc(100% - 110px);
  position: relative;
`;

const MainViewEditorBotttomBar = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 60px;
  background: #222222;

  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
`;

const ActionButton = styled(props => <Button {...props} blue />)`
  &&& {
    width: 170px;
    height: 34px;
    margin: 0 10px;
  }
`;

const CancelButton = styled(props => <Button {...props} unframed />)`
  &&& {
    width: 100px;
    height: 34px;
    margin-right: 30px;
    color: white;
    justify-content: center !important;

    &:before {
        background: #333;
    }
  }
`;

class MainViewEditor extends React.Component {
    constructor(props) {
        super(props);

        this.editorRef = React.createRef();

        this.state = {
            fetching: true,
            showThemeDialog: false,
            showUploadFontsDialog: false,
            showSaveDialog: false,
            themeModel: null,
            currentSlideTemplate: null,
            customFonts: [],
            builtInFonts: [],
            showEditor: false,
            showThemeEditor: false
        };

        this.dummyPresentation = null;
    }

    get slideModel() {
        return this.dummyPresentation?.slides.models[0];
    }

    get slideCanvas() {
        return PresentationEditorController.getCurrentCanvasController()?.canvas;
    }

    async componentDidMount() {
        const { themeModel } = this.props;

        if (themeModel) {
            this.handleThemeSelected(themeModel);
        } else {
            const response = await AppController.user.isEduUser();
            this.setState({ showThemeDialog: true, fetching: false, isEduUser: response.isEduUser });
        }
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, resolve));
    }

    handleThemeSelected = async theme => {
        const { isCreatingNewSlide } = this.props;
        const response = await AppController.user.isEduUser();

        const themeModel = (theme instanceof Theme) ? theme.attributes : theme;

        if (themeModel.needsFontsMap) {
            this.setState({
                themeModel,
                showThemeDialog: false,
                showUploadFontsDialog: true
            });
            return;
        }

        await new Promise(resolve => this.setState({
            themeModel,
            showThemeDialog: false,
            showUploadFontsDialog: false,
            fetching: true
        }, resolve));

        try {
            await this.createDummyPresentation();

            if (isCreatingNewSlide) {
                await this.createSlide();
            } else {
                await this.loadSlide();
            }

            app.undoManager.reset();

            this.setState({
                showEditor: true,
                isEduUser: response.isEduUser
            });
        } catch (err) {
            logger.error(err, "[MainViewEditor] handleThemeSelected() failed");
        }
    }

    async createDummyPresentation() {
        const { firebaseUser } = this.props;
        const { themeModel } = this.state;

        this.dummyPresentation = new Presentation({
            name: "Slide",
            userId: firebaseUser.uid,
            slideRefs: {},
            _migrated: true,
            // Respecting current workspace set by the MainView initializer
            orgId: AppController.workspaceId === "personal" ? null : AppController.workspaceId,
            isDummy: true
        }, {
            disconnected: true,
            userId: firebaseUser.uid
        });

        await this.dummyPresentation.load();

        this.dummyPresentation.permissions.write = true;
        this.dummyPresentation.permissions.read = true;

        const dummyTheme = new Theme(themeModel, { disconnected: true });
        await app.themeManager.saveThemeToPresentation(dummyTheme, this.dummyPresentation);
        await app.themeManager.loadTheme(this.dummyPresentation);

        ds.selection.presentation = this.dummyPresentation;
        await PresentationEditorController.setPresentation(this.dummyPresentation);
    }

    async createSlide() {
        const { onClose } = this.props;

        const shouldShowSmartSlidesDialog = !AppController.user.get("hasUsedPPTAddin");

        if (shouldShowSmartSlidesDialog) {
            AppController.user.update({ hasUsedPPTAddin: true });
            await AppController.user.updatePromise;
        }

        const [slideWasAdded] = await Promise.all([
            ShowDialogAsync(
                AddSlideContainer,
                {
                    forceShowTeamSlides: true,
                    hidePPTImport: true,
                    hideUseCurrentThemeSwitch: true
                }
            ),
            shouldShowSmartSlidesDialog ? ShowDialogAsync(SmartSlidesDialog) : Promise.resolve()
        ]);

        if (!slideWasAdded) {
            onClose();
        }
    }

    async loadSlide() {
        const { slideModel, teamSlideId, firebaseUser } = this.props;

        if (teamSlideId) {
            const teamSlide = new Slide({ id: teamSlideId }, { presentation: this.dummyPresentation, userId: firebaseUser.uid });
            await teamSlide.load();

            const teamSlideModel = { ...teamSlide.attributes };
            if (teamSlideModel.isTeamSlideTemplate) {
                // Templates should be treated as regular slides
                delete teamSlideModel.libraryItemId;
            }

            await this.dummyPresentation.createSlides([teamSlideModel], 0, { disconnected: true, skipSlidesCleanup: true });
        } else {
            await this.dummyPresentation.createSlides([slideModel], 0, { disconnected: true, skipSlidesCleanup: true });
        }
    }

    handleEditorLoaded = async () => {
        const { isCreatingNewSlide, slidePlaybackStage } = this.props;

        if (!isCreatingNewSlide && slidePlaybackStage) {
            await this.slideCanvas.goToStage(slidePlaybackStage, false);
        }

        await this.setStateAsync({
            currentSlideTemplate: new this.slideCanvas.slideTemplates[this.slideModel.get("template_id")]()
        });

        if (this.slideModel.has("libraryItemId")) {
            // Save slide as image immediately if slide is a team slide
            await this.handleSave(true);
            return;
        }

        // Tracking template change
        this.slideModel.on("change:template_id", slideModel => {
            this.setState({
                currentSlideTemplate: new this.slideCanvas.slideTemplates[slideModel.get("template_id")](),
            });
        });

        this.setState({
            fetching: false
        });
    }

    handleSave = async saveAsImage => {
        const { onSaveSlide } = this.props;
        const { currentSlideTemplate } = this.state;

        ShowDialog(ProgressDialog, {
            title: "Saving..."
        });

        // Make sure all slide changes are saved to model
        await this.slideCanvas.saveCanvasModel();

        // Export to ppt slide(s)
        const { base64Data } = await new ExportToPPT().export({
            slideCanvases: [this.slideCanvas],
            debug: false,
            destination: ExportToPPT.EXPORT_DESTINATION.base64,
            showDialogs: false,
            exportSlidesAsImages: saveAsImage
        });

        // Pull theme from presentation in case it was changed/updated
        const theme = app.themeManager.loadThemeFromPresentation(this.dummyPresentation);

        onSaveSlide({
            slideModel: this.slideModel.attributes,
            themeModel: theme.attributes,
            pptSlideData: base64Data,
            workspaceId: AppController.workspaceId,
            templateId: currentSlideTemplate.id,
            templateTitle: currentSlideTemplate.title,
            isSavedAsImage: saveAsImage
        });
    }

    handleShowSaveSlideDialog = async () => {
        await PresentationEditorController.hideSelectionLayer();
        await PresentationEditorController.setHandleKeyEvents(false);

        this.setState({ showSaveDialog: true });
    }

    handleCloseSaveSlideDialog = async () => {
        await PresentationEditorController.showSelectionLayer();
        await PresentationEditorController.setHandleKeyEvents(true);

        this.setState({ showSaveDialog: false });
    }

    render() {
        const {
            onClose,
            firebaseUser,
            pptThemeModel,
            isCreatingNewSlide
        } = this.props;

        const {
            fetching,
            showThemeDialog,
            showUploadFontsDialog,
            showSaveDialog,
            themeModel,
            currentSlideTemplate,
            showEditor,
            isEduUser
        } = this.state;

        // if it's a team  workspace, check if the user has access to custom themes, otherwise default to true
        const canAccessCustomTheme = AppController.orgId
            ? app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CUSTOM_THEMES, AppController.workspaceId) : true;

        return (<MainViewEditorContainer>
            {/* Click shield */}
            <FetchingClickShield
                visible={fetching}
                opaque
            />

            {showThemeDialog && <MuiThemeProvider theme={dialogTheme}>
                <ChooseThemeDialog
                    title="Select a Beautiful.ai theme to start from."
                    subtitle="Themes define the look of your slide, including fonts and colors. You can modify and save your theme to use again later."
                    selectButtonLabel="Create slide"
                    closeDialog={onClose}
                    closeDialogOnThemeChoose={false}
                    hideBuiltInThemes={!canAccessCustomTheme}
                    hidePPTImport
                    showCancelButton
                    preventClose
                    // If we just opened the editor, we don't have a themeModel yet
                    showEditCurrentTheme={!!themeModel}
                    pptThemeModel={pptThemeModel}
                    callback={this.handleThemeSelected}
                />
            </MuiThemeProvider>}
            {showUploadFontsDialog &&
                <UploadFontsDialog
                    themeModel={themeModel}
                    onFontsUploaded={this.handleThemeSelected}
                />
            }
            {showSaveDialog &&
                <SaveSlideDialog
                    saveAsImageDefaultValue={!isCreatingNewSlide}
                    onCancel={this.handleCloseSaveSlideDialog}
                    onSave={this.handleSave}
                    isEduUser={isEduUser}
                />
            }
            {/* Editor and UI*/}
            {showEditor && <>
                <MainViewEditorFrame>
                    <PresentationEditor
                        dummyPresentation={this.dummyPresentation}
                        presentationId={this.dummyPresentation.id}
                        onLoaded={this.handleEditorLoaded}
                        isSingleSlideEditor={true}
                        allowThemeChange={true}
                        hidePanels={[
                            PanelType.ANIMATION,
                            PanelType.RECORD,
                            PanelType.VERSION_HISTORY,
                            PanelType.COMMENTS,
                            PanelType.ADVANCE_ON,
                            PanelType.MORE_ACTIONS
                        ]}
                    />
                </MainViewEditorFrame>
                <PPTAddinEditorTopBar
                    firebaseUser={firebaseUser}
                    canvas={this.slideCanvas}
                    currentSlideTemplate={currentSlideTemplate}
                />
                <MainViewEditorBotttomBar>
                    <CancelButton onClick={onClose}>cancel</CancelButton>
                    <ActionButton onClick={this.handleShowSaveSlideDialog}>save</ActionButton>
                </MainViewEditorBotttomBar>
            </>}
        </MainViewEditorContainer>);
    }
}

export default MainViewEditor;
