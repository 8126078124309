import React, { Component } from "react";
import styled from "styled-components";
import ThemeSection from "js/editor/ThemeEditor/components/ThemeSection";
import PredefinedColorPalettePicker from "js/editor/ThemeEditor/components/Color/PredefinedColorPalettePicker";
import { PaletteColorType } from "common/constants";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { ColorChitCollection } from "js/editor/ThemeEditor/components/Color/ColorChitCollection";
import { _, tinycolor } from "js/vendor";
import { blendColors, trackActivity } from "js/core/utilities/utilities";
import FontControl from "js/editor/ThemeEditor/components/Font/FontControl";
import { ThemeSpinner } from "js/editor/ThemeEditor/components/Common/ThemeCommon";
import { Icon, Button, TextField } from "@material-ui/core";
import ThemeOptions from "js/editor/ThemeEditor/components/ThemeOptions";
import { FlexBox } from "js/react/components/LayoutGrid";
import UploadLogo from "js/editor/ThemeEditor/components/Common/UploadLogo";
import { getCurrentFontStyle } from "js/editor/ThemeEditor/panes/ThemeTypography";
import { Divider } from "js/react/components/UiComponents";
import { ThemeSettingsPane } from "js/editor/ThemeEditor/components/ThemeSettingsPane";
import { SimpleLabel } from "js/react/components/SimpleLabel";

const BackgroundOptions = styled(ThemeOptions)`
  .OptionIcon {
    background: #EEEEEF;

    .MuiIcon-root {
      width: 40px;
      height: 40px;
    }
  }
`;

export default class ThemeSimple extends Component {
    replacePalette = palette => {
        if (!palette.colors.hasOwnProperty("chart1")) {
            let index = 2;
            palette.colors["chart1"] = palette.colors.theme;

            for (let colorName of Object.keys(palette.colors)) {
                if (colorName.startsWith("accent")) {
                    palette.colors["chart" + index] = palette.colors[colorName];
                    index++;
                }
            }
        }
        if (!palette.colors.hasOwnProperty(PaletteColorType.BACKGROUND_ACCENT)) {
            let blendColor = tinycolor(palette.colors[PaletteColorType.THEME]).setAlpha(0.1);
            palette.colors[PaletteColorType.BACKGROUND_ACCENT] = blendColors(blendColor, tinycolor("white")).toRgbString();
        }

        if (!palette.colors.hasOwnProperty(PaletteColorType.POSITIVE)) {
            palette.colors[PaletteColorType.POSITIVE] = "#54C351";
        }
        if (!palette.colors.hasOwnProperty(PaletteColorType.NEGATIVE)) {
            palette.colors[PaletteColorType.NEGATIVE] = "#E04C2B";
        }

        this.props.update(palette);
    };

    handleUpdateColors = colors => {
        let paletteColors = Object.assign({}, this.props.theme.colors);

        // delete all accent colors
        for (let name of Object.keys(paletteColors)) {
            if (name.startsWith("accent")) {
                delete paletteColors[name];
            }
        }

        // add theme and accent colors
        for (let i = 0; i < colors.length; i++) {
            if (i == 0) {
                paletteColors.theme = colors[0];
            } else {
                paletteColors["accent" + i] = colors[i];
            }
        }

        this.props.update({
            colors: paletteColors,
            palette_name: "custom"
        });
    }

    handleNameChange = event => {
        let name = event.target.value;
        this.props.update({
            name,
        });
    }

    handleNameBlur = event => {
        let name = event.target.value.trim();
        if (name !== this.props.theme.name) {
            this.props.update({
                name,
            });
        }
    }

    handleChangeFont = (style, fontId, weight) => {
        const { update } = this.props;
        update({
            [`originalFont${style}FontId`]: null,
            [`font${style}FontId`]: fontId,
            [`font${style}Weight`]: weight,
            [`font${style}BoldWeight`]: null,
            [`font${style}LineHeight`]: 1.6,
            [`font${style}LetterSpacing`]: 0,
            [`font${style}TextTransform`]: "auto",
            [`font${style}Scaling`]: 100
        });
    }

    render() {
        let { theme,
            update,
            orgId,
            isSharedTheme } = this.props;

        let paletteColors = [];
        let chartColors = [];

        paletteColors.push(theme.colors.theme);
        for (let colorName of Object.keys(theme.colors)) {
            if (colorName.startsWith("accent")) {
                paletteColors.push(theme.colors[colorName]);
            }
            if (colorName.startsWith("chart")) {
                chartColors.push(theme.colors[colorName]);
            }
        }

        let isDarkBackground = theme.defaultBackgroundColor === "background_dark";

        return (
            <FlexBox column fillHeight left between>
                <ThemeSettingsPane>

                    <div>
                        <SimpleLabel tooltip={
                            <p>
                                <p>The color palette defines the theme colors available for decorations, shapes,
                                    accents, and emphasized text. You can choose up to 10 colors and drag to reorder
                                    them.</p>
                                <p>This palette also defines the available color backgrounds in your theme (in addition
                                    to the specific background colors you can define below).</p>
                                <p>NOTE: Generally, this palette should only contain actual colors and you should avoid
                                    adding colors to similar to black or white as they may have odd results on smart
                                    slides.</p>
                            </p>
                        }>
                            Colors
                            <PredefinedColorPalettePicker update={this.replacePalette} theme={theme} />
                        </SimpleLabel>
                        <ColorChitCollection
                            paletteName={theme.palette_name}
                            colors={paletteColors}
                            {...this.props}
                            onUpdate={this.handleUpdateColors}
                        />
                    </div>

                    <div>
                        <FontControl
                            simple
                            label="Header/Title Font"
                            selectedFontId={theme.fontHeaderFontId}
                            selectedFontWeight={theme.fontHeaderWeight}
                            onSelectFont={(fontId, weight) => this.handleChangeFont("Header", fontId, weight)}
                            update={update}
                            theme={theme}
                            orgId={orgId}
                            isSharedTheme={isSharedTheme}
                        />
                        <FontControl
                            simple
                            label="Body Font"
                            style="Body"
                            selectedFontId={theme.fontBodyFontId}
                            selectedFontWeight={theme.fontBodyWeight}
                            onSelectFont={(fontId, weight) => this.handleChangeFont("Body", fontId, weight)}
                            update={update}
                            theme={theme}
                            orgId={orgId}
                            isSharedTheme={isSharedTheme}
                        />
                    </div>

                    <BackgroundOptions
                        options={[
                            {
                                value: "background_light",
                                label: "light",
                                icon: <Icon>light_mode</Icon>,
                            },
                            {
                                value: "background_dark",
                                label: "dark",
                                icon: <Icon>nightlight_round</Icon>,
                            }
                        ]}
                        title="Background Style"
                        property="defaultBackgroundColor"
                        update={update}
                        theme={theme}
                    />
                    <div>
                        <SimpleLabel>Footer Logo</SimpleLabel>
                        <UploadLogo
                            uploadButtonIsExternal
                            id="upload-logo-simple"
                            bgColor={isDarkBackground ? theme.colors.background_dark : theme.colors.background_light}
                            src={isDarkBackground ? theme.logo_dark : theme.logo}
                            update={update}
                            attribute="logo"
                            attribute2="logo_dark"
                            onUploadSuccess={logo_id => {
                                let trackProps = {
                                    logo_changed: true,
                                    logo_dark_changed: true,
                                    source: "theme_editor_simple",
                                    logo_id,
                                };
                                if (isSharedTheme) {
                                    trackActivity("OrgTheme", "LogoUpload", null, null, trackProps, { audit: false });
                                } else {
                                    trackActivity("Theme", "LogoUpload", null, null, trackProps, { audit: false });
                                }
                            }}
                        />
                    </div>
                </ThemeSettingsPane>
            </FlexBox>
        );
    }
}

