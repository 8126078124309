import React, { Component } from "react";

import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { IconButton } from "js/Components/IconButton";

import PresentationEditorController from "../PresentationEditorController";
import { Icon } from "../../../Components/Icon";

const StyledCommentContainer = styled.div`
  position: relative;

    :hover {
        background-color: none;
    }

  .unread-comments-dot {
    background-color: #ffaa00;
    border-radius: 50%;
    border: 2px solid #333333;
    position: absolute;
    left: 21px;
    width: 7.2px;
    height: 7.2px;
    top: 1.2px;
    left: 15.6px;
    }

`;

const StyledIconButton = styled(IconButton)`   
    :hover {
        background: transparent;
    }
`;

export default class CommentsActions extends Component {
    state = {
        comments: []
    }

    componentDidMount() {
        this.commentsSubscription = ds.getObservables().slideComments$
            .subscribe(comments => this.setState({ comments }));
    }

    componentWillUnmount() {
        this.commentsSubscription?.unsubscribe();
    }

    handleToggleComments = () => {
        PresentationEditorController.toggleComments();
    }

    get hasComments() {
        const { comments } = this.state;
        return comments.some(comment => comment.authorUid !== app.user.id && (!comment.readBy || !comment.readBy[app.user.id]));
    }

    render() {
        return (
            <Tooltip title="Comment" enterDelay={700} arrow>
                <StyledCommentContainer data-test-id="show-comments">
                    <StyledIconButton size={24} onClick={this.handleToggleComments}>
                        <Icon color="white">maps_ugc</Icon>
                    </StyledIconButton>
                    {this.hasComments && <span className="unread-comments-dot" />}
                </StyledCommentContainer>
            </Tooltip>);
    }
}

